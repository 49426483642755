import { ref } from 'vue';

export default class QuickGuidePreviewComponent {
	public previewHeight = ref(0);
	public previewWidth = ref(0);

	public mounted(): void {
		const notification = document.querySelector('.js-sessionExpirationNotificationOuterWrapper[data-wrapper-visible="true"]');
		// Height to be deducted is 57 (header) + 51 (nav back block) + 32 (spacing) + 60 (notification) + 1 (a bit of margin).
		this.previewHeight.value = window.innerHeight - (notification ? 202 : 142);
		// Width to be deducted is 60 (vertical navbar) + 64 (spacing).
		this.previewWidth.value = window.innerWidth - 124;
	}
}
