import { defineComponent, onMounted } from 'vue';
import QuickGuidePreviewComponent from '@/presentation/components/client/quick-guide-preview/quick-guide-preview-class';

export default defineComponent({
	setup: () => {
		const component = new QuickGuidePreviewComponent();

		onMounted(component.mounted.bind(component));

		return {
			previewHeight: component.previewHeight,
			previewWidth: component.previewWidth
		};
	}
});
