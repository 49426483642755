import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "QuickGuidePreview",
    style: _normalizeStyle(`max-height: ${_ctx.previewHeight}px`)
  }, [
    _createElementVNode("iframe", {
      src: "https://smt-resources.s3.eu-west-1.amazonaws.com/ERT-quick-guide.pdf",
      width: `${_ctx.previewWidth}px`,
      height: `${_ctx.previewHeight}px`
    }, null, 8 /* PROPS */, _hoisted_1)
  ], 4 /* STYLE */))
}